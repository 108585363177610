const Popup = ({ message, success }) => {
  return (
    <div
      className="absolute left-0 bottom-0 rounded-md"
      style={{
        background: success ? "green" : "red",
      }}
    >
      <div className="mx-auto my-4 px-4 py-2.5 text-white text-lg w-25%">
        {message}
      </div>
    </div>
  );
};

export default Popup;
