import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUserAuth } from "../../firebase-auth";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";

import Header from "../../components/Header";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { verifyCode, resetPassword } = useUserAuth();
  const [reqMet, setReqMet] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    verifyCode(searchParams.get("oobCode"))
      .then((resp) => setEmail(resp))
      .catch((error) => {
        if (error.message.includes("invalid-action-code")) {
          setMessage("Your password reset code is invalid");
        }
        setError(false);
      });
  }, [searchParams]);

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password,
      secondPassword: passwordConfirm,
    });

  useEffect(() => {
    if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
      setReqMet(true);
      setFocus(false);
    } else setReqMet(false);
  }, [validLength, hasNumber, upperCase, lowerCase, match, specialChar]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    await resetPassword(searchParams.get("oobCode"), password);
    const authorize = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      response_type: "token",
      scope: "profile",
    };
    const params = new URLSearchParams(authorize);
    window.location.assign(
      `${process.env.REACT_APP_FUNCTIONS_URL}/authorize/entry?${params}`,
    );
  };

  return (
    <main className="h-screen flex">
      <Header
          headerH2="Reset Password"
          headerText="Enter your new password below."
        />
        <div className="w-2/5 flex flex-col gap-10 items-center justify-center bg-community-bg-secondary px-4">
        {error ? (
                  <h1>{message}</h1>
                ) : (
                  <form
                    className="flex flex-col gap-4 items-center justify-center"
                    onSubmit={handlePasswordSubmit}
                  >
                    <h1 className="my-4 text-4xl text-white">
                      Reset Password for {email}
                    </h1>
                        <input
                          className={`shadow-sm appearance-none rounded-lg w-96 text-xl py-2 px-5 leading-tight ${reqMet ? "focus:border-8 focus:border-green-500 focus:ring-4 focus:ring-green-400": 
                          "focus:border-8 focus:border-red-500 focus:ring-4 focus:ring-red-500"}`}
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                    <input
                          className={`shadow-sm appearance-none rounded-lg w-96 text-xl py-2 px-5 leading-tight ${match ? "focus:border-8  focus:border-green-500 focus:ring-4 focus:ring-green-400":
                          "focus:border-8 focus:border-red-500 focus:ring-4 focus:ring-red-500"}`}
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          required
                        />
                    <div className="flex jus items-center mt-4">
                      {reqMet & match ? (
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 text-xs font-semibold tracking-widest text-white uppercase transition duration-150 ease-in-out bg-community-primary border border-transparent rounded-md bg-community-button-primary false"
                  >
                    Update Password
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={true}
                    className="inline-flex items-center px-4 py-2 text-xs font-semibold tracking-widest text-white uppercase transition duration-150 ease-in-out bg-slate-400 border border-transparent rounded-md active:bg-gray-900 false"
                  >
                    Update Password
                  </button>
                )}
                    </div>
                  </form>
                )}
        </div>
    </main>
  );
};

export default ResetPassword;