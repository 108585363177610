import { useState } from "react";
import { useUserAuth } from "../../firebase-auth";
import Popup from "../../components/popup-alert";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PasswordResetEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { sendResetEmail } = useUserAuth();
  const { t } = useTranslation()
  const [alert, setAlert] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendResetEmail(email)
      .then((response) => {
        setPopupMessage("Password reset email sent, check your inbox");
        setSuccess(true);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 8000);
        console.log(response);
      })
      .catch((error) => {
        if (error.message.includes("user-not-found")) {
          setMessage("Invalid Email");
          setPopupMessage("User Not Found");
          setSuccess(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 6000);
          setTimeout(() => {
            setMessage("");
          }, 6000);
        }
      });
  };
  return (
    <div className="w-full h-full flex justify-center items-center">

      <form className="flex flex-col   justify-center items-center" onSubmit={handleSubmit}>
        <div className="w-full">
        
<i onClick={()=>navigate("/community/login")}  class="fa fa-angle-left p-3 px-[16px] bg-[#EEF1F8] cursor-pointer rounded-[50%]"></i>
       
          
        </div>
        <div className="w-full mt-3 ">
          <p className=" font-inter text-[28px] font-[700] text-[#484848]">Reset Password</p>
          <p className="font-inter text-[15px] font-[400] text-[#484848] ">
            Enter your email address to receive a password reset link
          </p>
          <div class="relative">
            <input onChange={(e) => { setEmail(e.target.value) }}
              id="email"
              type="email"
              value={email}
              required
              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$"
              class="block mt-7 px-2.5 pb-3.5 py-3 w-full text-sm border border-[#CDD1DF] text-gray-900 bg-transparent rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#CDD1DF] focus:outline-none focus:ring-0 focus:border-[#CDD1DF] peer" placeholder="" />
            <label for="email" class="absolute text-sm text-[#CDD1DF] dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-[#CDD1DF] peer-focus:dark:text-[#CDD1DF] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3">Email ID</label>
          </div>

          <div className="mt-5">
            <button
              className="bg-[#19415E] text-white text-xl font-semibold w-96 xs:w-full xs:text-lg py-3 rounded-[87px] focus:outline-none focus:shadow-outline mt-3"
              type="submit"
            >
              {t("Reset Password")}
            </button>
          </div>
          <div className=" mt-10 w-full flex justify-center text-center">
  <p className="text-sm text-[#757575]">{t("Don’t have an account?")}</p>
            <a href="/community/verification" className="flex" >
            
              <p className="ml-1 text-[15px] text-[#23415C] font-bold">{t("Sign Up")}</p>
            </a>
          </div>

        </div>

        {/* <input
                        className=" text-black shadow-sm appearance-none border-gray-300 rounded-lg w-96 text-xl py-2 px-5 leading-tight focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        type={"email"}
                        name="email"
                        id="email"
                        placeholder={t("Email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      /> */}

        <div className="flex justify-evenly mt-4 gap-4">

          {/* <button
                      className=" py-2 px-4 text-xs font-semibold  text-white uppercase transition duration-150 ease-in-out bg-community-button-primary border border-transparent rounded-md active:bg-gray-900 false"
                      type="button"
                      onClick={() =>
                        window.location.assign(
                          `https://${window.location.host}/community/login`,
                        )
                      }
                    >
                      {t("Return to Login")}
                    </button> */}
        </div>
      </form>
      {message !== "" ? (
        <div style={{ border: "3px solid red", color: "orangered" }}>
          {message}
        </div>
      ) : null}
      {alert ? <Popup message={popupMessage} success={success} /> : null}
    </div>
  );
};

export default PasswordResetEmail;
