import React, { useEffect, useState } from "react";
import { updateName } from "../firebase-functions";
import { useNavigate, useParams } from "react-router-dom";
import { useUserAuth } from "../firebase-auth"
import { getUser } from "../firebase-functions";
import { useTranslation } from "react-i18next";

const NameUpdateForm = () => {
  const { user, signOutUser } = useUserAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    console.log(params.userId)
  }, [user]);

  useEffect(() => {
    if (!user) {
      getUser({ uid: params.userId }).then((res) => {
        console.log("GET USER ", res);
        let displayName = res.data.displayName.split(" ");
        setFirstName(displayName[0]);
        setLastName(displayName[1]);
      });
    } else {
      let displayName = user?.displayName.split(" ");
      if (displayName !== undefined) {
        setFirstName(displayName[0]);
        setLastName(displayName[1]);
      }
    }
  }, []);

  useEffect(() => {
    console.log("USER ", user, "PARAMS: ", params, "USER ID", params.userId);
    if (!user && params.userId === undefined) {
      setMessage("No user logged in, redirecting to login");
      setAlert(true);
      console.log("Redirecting");
      // setTimeout(() => {
      //   navigate("/community/login");
      // }, 60000);
    }
  }, [user]);

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    }
    if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    let uid = user?.id || params.userId;
    updateName({ uid, name: `${firstName} ${lastName}` })
      .then((resp) => {
        setMessage(resp.data.status);
        setSuccess(resp.data.success);
        setAlert(true);
        setTimeout(() => {
          navigate("/community/login");
        }, 8000);
      })
      .catch((error) => {
        setMessage(error.message);
        setSuccess(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 8000);
      });
  };

  return (
<form
                  className="flex flex-col items-center gap-4 xs:w-5/6"
                  onSubmit={handleNameSubmit}
                >
                  <h1 className="text-white text-4xl xs:text-xl mt-4">{t("Update Name")}</h1>
                  <p className="text-white text-xl mb-4 xs:text-sm w-96">
                  &#9432; &ensp;
                  {t("You must log out of the app then log back in to update your name in 12M+")}
                  </p>
                      <input
                        className="shadow-sm text-black  appearance-none border-gray-300 rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        type="firstName"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                        pattern="[A-Za-z]{1,32}"
                      />
                      <input
                        className="shadow-sm text-black appearance-none border-gray-300 rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        type="lastName"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                        pattern="[A-Za-z]{1,32}"
                      />
                  <div className="flex items-center mt-4">
                    <button
                      className="bg-community-button-primary text-white text-xl font-semibold w-96 xs:w-full xs:text-lg py-2 rounded-xl focus:outline-none focus:shadow-outline mt-3"
                      type="submit"
                    >
                      {t("Save Name")}
                    </button>
                  </div>
                </form>
  )
}

export default NameUpdateForm