import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../firebase-auth";
import Header from "../../components/Header";
import { useSearchParams } from "react-router-dom";
import {
  logDeclineDisagreement,
  verificationDeclineStatus,
} from "../../firebase-firestore";
import { userIP } from "../../firebase-functions";
import { useTranslation } from "react-i18next";

const VerificationFailed = (props) => {
  const backLink = process.env.REACT_APP_COMMUNITY_URL;
  const { signOutUser } = useUserAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState([]);
  const [reason, setReason] = useState(null);
  const [message, setMessage] = useState(null);
  const [ipAddress, setIpAddress] = useState("0.0.0.0");
  const { t, i18n } = useTranslation()

  useEffect(() => {
    signOutUser();
    verificationDeclineStatus().then((res) => {
      console.log("Status", res);
      setStatus(res);
    });
  }, []);

  useEffect(() => {
    userIP().then((res) => {
      console.log("IP Address", res.data);
      setIpAddress(`${res.data}`);
    });
  }, []);

  const redirect = () => {
    window.location.assign(backLink);
  };

  const submitReason = (e) => {
    e.preventDefault();
    if (reason != "") {
    logDeclineDisagreement({
      email: decodeURIComponent(searchParams.get("email")),
      reason,
      ipAddress,
      date: new Date(Date.now()),
    });
    setMessage("Your complaint has been logged");
    setTimeout(() => {
      setMessage(null);
      window.location.assign(backLink);
    }, 6000);
  } else {
    redirect();
  }
  };

  return (
    <>
          {/* <form onSubmit={submitReason} className=" text-center flex flex-col gap-8">
          <div className="flex flex-col gap-8">
            <label className="flex flex-col items-center text-white text-center font-medium text-xl font-lato mb-2">
              {t("verificationFailed_label")}
            </label>
            <select
              onChange={(e) => {
                setReason(e.target.value);
              }}
              className="shadow-sm  border-gray-300 rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mx-auto"
            >
              <option value={null}>{t("Choose an option")}</option>
              {status.map((obj) => (
                <option value={obj.label} key={obj.label}>{t(obj.label)}</option>
              ))}
            </select>
          </div>
              <button
                className="bg-community-button-primary text-white text-2xl font-medium py-5 rounded focus:outline-none focus:shadow-outline mt-3 w-96 mx-auto"
                style={{
                  padding: "1rem",
                }}
                type="submit"
                onClick={redirect}
              >
                {t("Back to Twelve Million Plus")}
              </button>
        </form> */}
         {message && (
        <div className="absolute left-0 bottom-0 h-[max-content] py-2 px-3 bg-green-700 text-white">
          {message}
        </div>
      )}
    </>
  );
};

export default VerificationFailed;