import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../firebase-auth";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom"
import { userIP } from "../../firebase-functions";
import { logOauth2Error } from "../../firebase-firestore";

const LoginFailed = (props) => {
  const { signOutUser, user } = useUserAuth();
  const [ipAddress, setIpAddress] = useState("0.0.0.0");

  const errorData = {
    email: user?.email || "",
    name: user?.DisplayName || "",
    action: "Login-Failed",
    occurence: new Date(Date.now()),
    error: "Incorrect claims on user"
  };

  useEffect(() => {
    // userIP().then((res) => {
    //   console.log("IP Address", res.data);
    //   setIpAddress(`${res.data}`);
    // });
    // logOauth2Error()
    // const errorInstance = {
    //   ...errorData,
    //   description: "Error with firebase-auth function",
    // };
    // logOauth2Error(errorInstance);
    // signOutUser();
  }, []);

  const redirect = () => {
    window.location.replace(
      "http://localhost:3000/community/login",
    );
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <button
        className="bg-community-button-primary text-white text-xl font-semibold  xs:w-full xs:text-lg py-2 rounded-xl focus:outline-none focus:shadow-outline mt-3"
        type="button"
        onClick={redirect}
      >
        Back to Twelve Million Plus
      </button>
    </div>
  );
};

export default LoginFailed;

