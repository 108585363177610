import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login from "./pages/TwelveMillionPlus/Login";
import RegistrationVerification from "./pages/TwelveMillionPlus/RegistrationVerification";
import RegistrationForm from "./pages/TwelveMillionPlus/RegistrationForm";
import Profile from "./pages/TwelveMillionPlus/Profile";
import ErrorPage from "./error-page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserAuthContextProvider } from "./firebase-auth";

import reportWebVitals from "./reportWebVitals";
import VerificationFailed from "./pages/TwelveMillionPlus/VerificationFailed";
import ResetPassword from "./pages/TwelveMillionPlus/ResetPassword";
import PasswordResetEmail from "./pages/TwelveMillionPlus/PasswordResetEmail";
import LoginFailed from "./pages/TwelveMillionPlus/Login-Failed";
import Loading from "./components/loading";
import i18n from "./i18n";
import Header from "./components/Header";
import { I18nextProvider } from "react-i18next";
import { Translations } from "./constants";

const router = createBrowserRouter([
  {
    path: "/",
    element: <TwelveMillionPlus />,
    errorElement: <ErrorPage />,
  },
  {
    path: "community/",
    element: <Header />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <Login />,
        errorElement: <ErrorPage />,
      },
      {
        path: "login-failed",
        element: <LoginFailed />,
        errorElement: <ErrorPage />
      },
      {
        path: "verification",
        element: <RegistrationVerification />,
        errorElement: <ErrorPage />,
      },
      {
        path: "registration",
        element: <RegistrationForm />,
        errorElement: <ErrorPage />,
      },
      {
        path: "verification-failed",
        element: <VerificationFailed />,
        errorElement: <ErrorPage />,
      },
      {
        path: "profile/:userId",
        element: <Profile />,
        errorElement: <ErrorPage />,
      },
      {
        path: "profile",
        element: <Profile />,
        errorElement: <ErrorPage />,
      },
      {
        path: "password/email",
        element: <PasswordResetEmail />,
        errorElement: <ErrorPage />,
      },
      {
        path: "password/reset",
        element: <ResetPassword />,
        errorElement: <ErrorPage />,
      },
      {
        path: "loading",
        element: <Loading />,
        errorElement: <ErrorPage />,
      }
    ]
  }
]);

function TwelveMillionPlus() {
  window.location.replace(`http://localhost:3000/community/login`);
  return null;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserAuthContextProvider>
    <I18nextProvider i18n={i18n}>
    <RouterProvider router={router} />
    </I18nextProvider>
    </UserAuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
