import { useEffect, useState } from "react";
import { userIP } from "../../firebase-functions";
import React from "react";
import Header from "../../components/Header";
import { limitVerificationAttempts } from "../../firebase-firestore";
import { useNavigate } from "react-router-dom";

function RegistrationVerification(props) {
  const [ipAddress, setIpAddress] = useState("");
  const [verificationAttempts, setVerificationAttempts] = useState(0);
  const queryString = new URL(window.location).searchParams;
  const [role, setRole] = useState(queryString.get("role"));
  const navigate = useNavigate();

  useEffect(() => {
    userIP().then((res) => {
      console.log("IP Address", res.data);
      setIpAddress(`${res.data}`);
    });
  }, []);

  useEffect(() => {
    limitVerificationAttempts({ ipAddress }).then((res) => {
      console.log("Verification Attempts", res);
      setVerificationAttempts(res);
    });
  }, [ipAddress]);


  return (
    <div className="w-full h-full flex justify-center ">
      <div className="mt-4 ml-5">
        <i onClick={() => navigate("/community/login")} class="fa fa-angle-left p-3 px-[16px] bg-[#EEF1F8] cursor-pointer rounded-[50%]"></i>
      </div>

      <div className="w-full h-full">
        <iframe src={`https://services.sheerid.com/verify/${process.env.REACT_APP_SHEERID_PROGRAM_ID}/`} className="w-full h-full" title="SheerId"></iframe>
      </div>

    </div>

  );
}

export default RegistrationVerification;