import React, { useEffect, useMemo, useState } from "react";
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import AuthCode from "react-auth-code-input";
import { useForm } from "react-hook-form";
import { useUserAuth } from "../firebase-auth";
import { friendlyFirebaseError } from "./Multifactor/friendlyFirebaseError";
import Loading from "./loading";

function MultiFactorSignIn(props) {
  const auth = useUserAuth();
  const { makePhoneAuthProvider, makeRecaptcha } = auth;
  const phoneAuthProvider = makePhoneAuthProvider();
  const [pending, setPending] = useState(false);
  const { handleSubmit, register, setValue } = useForm();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [verificationId, setVerificationId] = useState();
  const [mfaFormAlert, setMfaFormAlert] = useState(null);
  const recaptchaConfig = useMemo(() => ({
    size: "invisible",
    callback: function (response) { },
  }), []);

  useEffect(() => {
    setRecaptchaVerifier(makeRecaptcha("multifactor-signIn", recaptchaConfig));
  }, [recaptchaConfig]);

  useEffect(() => {
    if (props.mfaResolver && !verificationId && recaptchaVerifier) {
      getVerificationCode();
    }
  }, [props.mfaResolver, verificationId, recaptchaVerifier]);

  function getVerificationCode() {
    setPending(true);
    const phoneInfoOptions = {
      multiFactorHint: props.mfaResolver.hints[0],
      session: props.mfaResolver.session,
    };
    const maskedPhoneNumber = phoneInfoOptions.multiFactorHint.phoneNumber;

    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        setVerificationId(verificationId);
        props.onFormNotify({
          type: "info",
          message: `A confirmation code has been sent to ${maskedPhoneNumber}. Please enter the code below.`,
        });
      })
      .catch((error) => {
        props.onFormNotify({
          type: "error",
          message: friendlyFirebaseError(error),
        });
      })
      .finally(() => {
        setPending(false);
      });
  }

  function sendVerificationCode({ verificationCode }) {
    setPending(true);
    const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

    props.mfaResolver.resolveSignIn(multiFactorAssertion)
      .then(() => {
        props.onFormNotify({
          type: "info",
          message: "Please wait while we verify your code",
        });
        setPending(false);
        props.setShouldMfaStart(false)
      })
      .catch((error) => {
        setPending(false);
        props.onFormNotify({
          type: "error",
          message: friendlyFirebaseError(error),
        });
      });
  }

  return (
    <>
      {verificationId && (
        <>
          {pending ? (
            <div className="flex justify-center h-[256px]">
              <Loading />
            </div>
          ) : (
            <>
              {mfaFormAlert && (
                <div className="my-4">
                  {/* <FormAlert alert={mfaFormAlert} /> */}
                </div>
              )}
              <div className="w-full flex justify-between">
                <h1 className="text-[20px] font-[700] text-community-text-primary">
                  Multi-factor Authentication
                </h1>
                <div>
                  <div className="border border-text-community-input-field-border-primary border-[3px] py-2 px-[15px] rounded-[50%]">
                    <button
                      onClick={() => props.setShouldMfaStart(false)}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <i className="fas fa-times text-1xl"></i>
                    </button>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(sendVerificationCode)} className="flex flex-col mb-2">
                <div className="my-4 flex justify-center">
                  <input
                    className="px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                    name="verificationCode"
                    type="hidden"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    {...register("verificationCode", {
                      required: true,
                    })}
                  />
                  <AuthCode
                    containerClassName="flex flex-row"
                    inputClassName="mr-3 xs:w-10 h-[54px] w-full border border-[#DDDDDD] rounded-[5px] xs:text-xl text-2xl font-bold text-center"
                    alphanumeric="numeric"
                    ariaLabel="mobile confirmation code"
                    onChange={(code) => setValue("verificationCode", code)}
                  />
                </div>
                <div className="w-full mt-2">
                  <p className="text-[15px] text-[#484848] font-medium">
                    A verification code has been sent to the phone number provided.
                    Please enter the 6-digit code to proceed.
                  </p>
                </div>
                <div className="mt-5 mr-2 w-full flex justify-end">
                  <p className="text-[15px] text-[#484848] font-medium">
                    Didn’t receive the code?
                  </p>
                  <p
                    onClick={getVerificationCode}
                    className="ml-1 text-[15px] text-[#484848] font-medium underline cursor-pointer underline-offset-4"
                  >
                    Resend Code
                  </p>
                </div>
                <div className="flex mt-5 mb-2 flex justify-center w-full">
                  <button
                    className="bg-[#19415E] text-white text-xl font-semibold w-96 xs:w-full xs:text-lg p-4 rounded-[67px] focus:outline-none focus:shadow-outline mt-3"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </>
          )}
        </>
      )}
      <div className="mt-12 w-full flex justify-center">
        <div id="multifactor-signIn"></div>
      </div>
    </>
  );
}

export default MultiFactorSignIn;
