import firebaseApp from "./firebaseConfig";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  getDoc,
  query,
  where,
  getCountFromServer,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
const db = getFirestore(firebaseApp);

const logOauth2Error = async (data) => {
  const docRef = await addDoc(collection(db, "oauth2_failure_logs"), data);
  return docRef;
};

const verificationDeclineStatus = async () => {
  let docs = [];
  const statuses = collection(db, "oauth2_verification_error_status");
  const docRef = await getDocs(statuses);
  docRef.forEach((doc) => {
    docs.push(doc.data());
  });
  return docs;
};

const logDeclineDisagreement = async (data) => {
  console.log("DECLINE: ", data);
  const docRef = await addDoc(
    collection(db, "oauth2_verification_decline_disagree"),
    data,
  );
  return docRef;
};

const limitVerificationAttempts = async (data) => {
  const attempts = collection(db, "oauth2_failure_logs");
  const collectionRef = query(
    attempts,
    where("ipAddress", "==", data.ipAddress),
  );
  const attemptCount = await getCountFromServer(collectionRef);
  return attemptCount.data().count;
};
const getVerifiedUserData = async (email) => {
  try {


    const verificationCollection = collection(db, "Users");
    const verifiedUserQuery = query(
      verificationCollection,
      where("email", "==", email)
    );
    const querySnapshot = await getDocs(verifiedUserQuery);
    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs.map(doc => doc.data());
      console.log(userData);
      return userData;
    } else {
      console.log('No matching documents.');
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

const getReferredBy = async () => {
  const referral = [];
  const referralRef = collection(db, "Comm_Referred_By");
  const docRef = await getDocs(referralRef);
  docRef.forEach((doc) => {
    referral.push(doc.data());
  });
  return referral;
};

const updateUserReferral = async (uid, referral) => {
  console.log("REFERRAL: ", uid, referral);
  const docRef = doc(db, "Users", `${uid}`);
  await setDoc(docRef, { comm_referred_by: `${referral}` }, { merge: true });
};

const createAuthOrigin = async (data) => {
  const docRef = await addDoc(collection(db, "AuthOrigin"), data);
  return docRef;
};

const updateAuthOrigin = async ({ docId, data }) => {
  const docRef = doc(db, "AuthOrigin", `${docId}`);
  await updateDoc(docRef, data);
};

const getInviteCode = async (inviteCode) => {
  const docRef = doc(db, "Invitations", `${inviteCode}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return true;
  } else {
    return null;
  }
};

export {
  logOauth2Error,
  verificationDeclineStatus,
  logDeclineDisagreement,
  limitVerificationAttempts,
  getReferredBy,
  updateUserReferral,
  createAuthOrigin,
  updateAuthOrigin,
  getInviteCode,
  getVerifiedUserData
};
