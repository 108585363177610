import { useEffect, useState } from "react";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { useUserAuth } from "../../firebase-auth";
import { useFetcher, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import { checkUser, userIP, communityNameUpdate } from "../../firebase-functions";
import 'react-phone-number-input/style.css';
import MultiFactorSignIn from "../../components/MultiFactorSignIn";
import Loading from "../../components/loading";

import {
  logOauth2Error,
  getReferredBy,
  updateUserReferral,
  createAuthOrigin,
  updateAuthOrigin,
  getInviteCode,
  getVerifiedUserData
} from "../../firebase-firestore";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import { method } from "lodash";
import { faLoveseat } from "@fortawesome/pro-regular-svg-icons";

function RegistrationForm(props) {
  const { signUp, addDisplayName, signOutUser, user, handleMultiFactorSignIn } = useUserAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifiedUserInfo, setVerifiedUserInfo] = useState()
  const [loading, setLoading] = useState(false)

  const code = searchParams.get("verificationId");
  const verified = searchParams.get("verified");
  const inviteCode = searchParams.get("inviteCode");
  const { t } = useTranslation()
  const [userIPAddress, setUserIPAddress] = useState("");

  const { email, lastName, firstName, } = verifiedUserInfo?.personInfo || {}
  const { subSegment } = verifiedUserInfo?.lastResponse || {}

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${process.env.REACT_APP_SHEERID_TOKEN}`
  };

  async function userData() {
    const response = await fetch(`https://services.sheerid.com/rest/v2/verification/${code}/details`,
      { headers }
    );
    const data = await response.json();
    if (data) setVerifiedUserInfo(data)
  }
  useEffect(() => {
    userData()
  }, [])

  // cleaning it up to be capitalized
  const toTitleCase = (phrase) => {
    return phrase
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const capitalizedFirstName = toTitleCase(searchParams.get("firstName"));
  const capitalizedLastName = toTitleCase(searchParams.get("lastName"));


  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();
  const [mfaAlert, setMfaAlert] = useState();

  const [userExists, setUserExists] = useState(false);
  const navigate = useNavigate();
  const [headerString, setHeaderString] = useState("Thank you, please wait while we verify you.");
  const [checkingUser, setCheckingUser] = useState(true);
  const [referredBy, setReferredBy] = useState([]);

  const errorData = {
    email,
    name: `${firstName} ${lastName}`,
    action: "Registration",
    occurence: new Date(Date.now()),
  };

  useEffect(() => {
    getReferredBy().then((response) => {
      setReferredBy(response);
    });

  }, []);

  useEffect(() => {
    setLoading(true)
    const fetchUserData = async () => {
      const data = await getVerifiedUserData(email);
      if (data) {
        if (email == data[0].email) {
          window.location.replace(`${process.env.REACT_APP_BASE_URL}/community/login`)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    };
    fetchUserData();
  }, [email]);

  useEffect(() => {
    userIP().then((res) => {
      console.log("User IP: ", res.data);
      setUserIPAddress(res.data);
    });
    console.log((document.referrer !== "https://api.id.me/" && !email?.includes("registrationtester")), inviteCode === 'boo');
    const invited = getInviteCode(inviteCode);
    if (invited) {
      console.log("valid invite code");
      return;
    }
    else if (
      (document.referrer !== "https://api.id.me/" &&
        !email?.includes("registrationtester")) ||
      inviteCode === 'boo'
    ) {
      console.log("redirecting to verification");
      window.location.replace(
        "https://" + window.location.hostname + "/community/verification",
      );
    }
  }, []);

  const [error, setError] = useState(false);
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
    });

  const [reqMet, setReqMet] = useState(false);
  const [focus, setFocus] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralName, setReferralName] = useState("");

  const passwordFocus = () => {
    if (!reqMet) {
      setFocus(true);
    }
  };
  const passwordBlur = () => {
    setFocus(false);
  };
  // USE EFFECT TO check password
  useEffect(() => {
    if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
      setReqMet(true);
      setFocus(false);
    } else setReqMet(false);
  }, [validLength, hasNumber, upperCase, lowerCase, match, specialChar]);

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  // allows users to overried id.me name


  const auth_origin = {
    email: email,
    origin: "COMM",
    status: "started",
    lastUpdated: new Date(Date.now()),
    metadata: { browser: window.navigator.userAgent, ip: userIPAddress },
  }

  const handleRegistration = async (event) => {

    if (!reqMet) {
      setFocus(true);
    }
    setLoading(true)
    event.preventDefault();
    console.log(auth_origin)
    const data = new FormData(event.currentTarget);
    const fullName = `${firstName} ${lastName}`;
    console.log("Handle Registration Function gets trigger")

    // handleMultiFactorSignIn(
    //   setShouldMfaStart(true),

    // );


    checkUser({ email, verified })
      .then(async (resp) => {
        if (resp.data.status) {
          console.log("if response", resp)
          communityNameUpdate({ email, displayName: fullName })
          setUserExists(resp.data.status)
          navigate("community/login")
          setLoading(false)
        } else {
          console.log("else response", resp)
          const response = await signUp(email, password.firstPassword);
          console.log("response", response);
          addDisplayName(response?.user, fullName);
          setLoading(false)
        }
      })

    await createAuthOrigin(auth_origin)
      .then((res) => {
        console.log("Creating user", res.id);

        updateAuthOrigin({ docId: res.id, data: { ...auth_origin, status: "complete" } });
      }).catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      console.log("user exists");
      const referral = async () => {
        await updateUserReferral(
          user.uid,
          referralCode + " " + referralName,
        ).then(() => {
          console.log("referral updated");
          console.log("finished");
          signOutUser();
          window.location.assign(`${window.location.origin}/community/login`);
        });
      };
      referral();
    }
  }, [user]);

  useEffect(() => {
    try {
      checkUser({ email, verified }).then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setUserExists(res.data.status);
          if (res.data.member) {
            setHeaderString(
              `User Account for ${email} already exists. Redirecting to login page...`,
            );
            setTimeout(() => {
              window.location.assign(
                `${window.location.origin}${res.data.redirectPath}`,
              );
            }, 6000);
          } else {
            setHeaderString(
              `User Account for ${email} already exists. But you are not currently a member of Twelve Million Plus. Redirecting to verification page...`,
            );
            setTimeout(() => {
              window.location.assign(
                `${window.location.origin}${res.data.redirectPath}`,
              );
            }, 6000);
          }
          const errorInstance = {
            ...errorData,
            description: "User tried to register with existing user account",
            error: "User already exists",
          };
          // logOauth2Error(errorInstance);
        }
      });
      setTimeout(() => {
        setCheckingUser(false);
        setHeaderString("We are so glad you've found us and we can't wait to meet you! Please complete your final community registration below.")
      }, 6000);
    } catch (err) {
      console.log(err);
    }
  }, [email]);

  function camelCaseToTitleCase(str) {
    let words = str?.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1);
    words = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words?.join(' ');
  }


  return (
    <>
      {loading || !verifiedUserInfo ? <Loading /> :
        <div className="flex flex-col items-center ">
          <form onSubmit={handleRegistration}
            className="flex flex-col items-center"
          >
            <div className="w-full">
              <h1 className="text-[28px] font-bold text-[#484848] ">Registration</h1>
              <p className="mt-1 text-[#363750] text-[15px]">
                Please fill these details to create your account
              </p>
            </div>
            <div className="flex flex-col gap-2 mt-3 items-center">

              <div className="relative w-full mt-5">
                <input
                  id="milStatus"
                  type="email"
                  value={camelCaseToTitleCase(subSegment)}
                  defaultValue={camelCaseToTitleCase(subSegment)}
                  required
                  disabled
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$"
                  className="block w-full px-2.5 pb-3.5 py-3 bg-gray-100 w-full text-sm border border-community-input-field-border-primary text-gray-900  rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                />
                {!shouldMfaStart && <label
                  htmlFor="milStatus"
                  className="absolute text-sm text-[#9A9A9A]  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                >
                  Military Status
                </label>}
              </div>

              <div className="mt-2 w-full flex justify-between ">

                <div className="relative w-[48%]">
                  <input
                    id="firstName"
                    type="text"
                    name="given-name"
                    // updating fns, if users wants to update name                   
                    // setting the value to what is passed via params
                    value={firstName}
                    defaultValue={firstName}
                    required
                    autoComplete="given-name"
                    pattern="[A-Za-z]{1,32}"
                    disabled
                    className="block w-full px-2.5 pb-3.5 py-3 bg-gray-100 w-full text-sm border border-community-input-field-border-primary text-gray-900  rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                  />
                  {!shouldMfaStart && <label
                    htmlFor="firstName"
                    className="absolute text-sm text-[#9A9A9A]  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                  >
                    First Name
                  </label>}
                </div>

                <div className="relative w-[48%]">
                  <input
                    id="lastName"
                    type="text"
                    name="given-name"
                    value={lastName}
                    defaultValue={lastName}
                    required
                    autoComplete="given-name"
                    pattern="[A-Za-z]{1,32}"
                    disabled
                    className="block w-full px-2.5 pb-3.5 py-3 bg-gray-100 w-full text-sm border border-community-input-field-border-primary text-gray-900  rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                  />
                  {!shouldMfaStart && <label
                    htmlFor="lastName"
                    className="absolute text-sm text-[#9A9A9A]  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                  >
                    Last Name
                  </label>}
                </div>
              </div>
              <div className="relative w-full mt-2">
                <input
                  id="email"
                  type="email"
                  value={email}
                  defaultValue={email}
                  required
                  disabled
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$"
                  className="block bg-gray-100 w-full px-2.5 pb-3.5 py-3 w-full text-sm border border-community-input-field-border-primary text-gray-900 rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                />
                {!shouldMfaStart && <label
                  htmlFor="email"
                  className="absolute text-sm text-[#9A9A9A] dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                >
                  Email ID
                </label>}
              </div>


              <div className="relative w-full mt-2">
                <input
                  id="password"
                  onFocus={passwordFocus}
                  onChange={setFirst}
                  onBlur={passwordBlur}
                  type="password"
                  name="pass"

                  autoComplete="new-password"
                  required
                  className="block w-full px-2.5 pb-3.5 py-3  w-full text-sm border border-community-input-field-border-primary text-gray-900 bg-white rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                />
                {!shouldMfaStart && <label
                  htmlFor="password"
                  className="absolute text-sm text-[#9A9A9A]  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                >
                  Password
                </label>}
                {!reqMet && <p className="text-[10px] text-red-500 mt-1">* Password must be at least 8 characters long and include at least one uppercase letter, <br /> one lowercase letter, one number and one special character.</p>}
              </div>


              <div className="relative w-full mt-2">
                <input
                  id="email"
                  onChange={setSecond}
                  type="password"
                  name="password_confirmation"
                  autoComplete="new-password"
                  required
                  className="block w-full px-2.5 pb-3.5 py-3  w-full text-sm border border-community-input-field-border-primary text-gray-900 bg-white rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                />
                {!shouldMfaStart && <label
                  htmlFor="email"
                  className="absolute text-sm text-[#9A9A9A]  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                >
                  Confirm Password
                </label>}
              </div>
              <div className="w-full">
                {!match && password.secondPassword && <p className="text-red-500 text-[12px]">The password and confirm password does not match </p>}
              </div>

              {/* {focus ? (
                <div className="w-3/4 text-white text-md bg-community-bg-secondary absolute bottom-0 left-1/2 translate-y-[100%] -translate-x-[50%] border-2 border-white p-4 rounded-lg">
                  <ul className=" font-semibold text-center">
                    <li className="mb-2">1 UpperCase</li>
                    <li className="mb-2">1 LowerCase</li>
                    <li className="mb-2">1 Number</li>
                    <li className="mb-2">1 Symbol</li>
                    <li className="mb-2">8 characters</li>
                  </ul>
                </div>
              ) : null} */}


              <div className="flex flex-col items-center w-full">
                <div className="relative w-full mt-2">
                  <select

                    id="dropDown"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setReferralCode(e.target.value);
                    }}
                    name="referrer"
                    className="shadow-sm border h-[50px]  border-gray-300 rounded-lg w-full xs:w-full xs:text-lg text-sm py-2 px-3  leading-tight "
                  >
                    <option className="text-[15px] text-[#484848]" value={null}>{t("How did you hear about us?")}</option>
                    {referredBy.map((ref) => {
                      return (
                        <option className="text-sm text-[#484848]" key={ref.value} value={ref.label}>
                          {t(ref.label)}
                        </option>
                      );
                    })}
                  </select>
                  {!shouldMfaStart && <label
                    htmlFor="dropDown"
                    className="absolute text-sm text-[#9A9A9A]  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                  >
                    How do you know about us?
                  </label>}
                </div>
                {referralCode === "Referred by" && (
                  <div>
                    <div className="flex flex-col items-start">
                      <input
                        type="text"
                        name="referredName"
                        // updating fns, if users wants to update name
                        onChange={(e) => {
                          setReferralName(e.target.value);
                        }}
                        // setting the value to what is passed via params
                        defaultValue={referralName}
                        className="shadow-sm appearance-none border-gray-300 rounded-lg w-96 text-xl py-2 px-5 leading-tight focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>
                  </div>
                )}
                <div className="flex w-full justify-start mt-2">
                  <label
                    className="flex  items-center justify-start shadow-sm appearance-none border-gray-300 rounded-lg w-96 xs:w-full text-xl py-2 leading-tight focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-white">
                    <input
                      type="checkbox"
                      required
                      className="mr-4 w-[21px] h-[21px]"
                    />
                    <p className=" w-full text-[13px] font-normal  text-[#000000]" >
                      Twelve Million Plus is a community exclusive to military spouses. I attest that I am a military spouse.
                    </p>
                  </label>

                </div>
              </div>
            </div>
            <div className="flex items-center w-full justify-center mt-1 mb-2 xs:mt-7">
              {reqMet & match ? (
                <button
                  type="submit"
                  className="bg-[#19415E] text-white  font-normal   w-96 xs:w-full xs:text-lg py-4 border rounded-[60px] focus:outline-none focus:shadow-outline mt-3"
                >
                  {t("Complete Registration")}
                </button>
              ) : (
                <button
                  type="submit"

                    className="bg-gray-300 text-white   font-normal w-full xs:w-full xs:text-lg py-4 rounded-[60px] focus:outline-none focus:shadow-outline mt-3"
                >
                  {t("Complete Registration")}
                </button>
              )}
            </div>
          </form>
          {shouldMfaStart && (
            <div className="fixed w-screen flex h-screen flex justify-center items-center bg-black bg-opacity-50 top-0 left-0">
              <div className="h-[55%] p-7 w-[36%] absolute left-1/3 rounded-lg m-auto top-[18%] bg-white">
                <MultiFactorSignIn resolver={mfaResolver} setShouldMfaStart={setShouldMfaStart} />
              </div>
            </div>
          )}
        </div>
      }
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              viewBox="0 0 20 20"
              onClick={() => setError(false)}
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
    </>
  );
}

export default RegistrationForm;
