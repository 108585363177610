import { useState, useEffect } from "react";
import { useUserAuth } from "../../firebase-auth";
import Header from "../../components/Header";
import Popup from "../../components/popup-alert";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { updatePassword } from "../../firebase-functions";
import NameUpdateForm from "../../forms/update-name";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Profile = () => {
  const { user, signOutUser } = useUserAuth();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation()
  
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    console.log("Profile Origin ", window.location.origin);
    if (window.location.origin !== `${process.env.REACT_APP_BASE_URL}`) {
      console.log("Back to 12M+");
    }
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
    });

  const [reqMet, setReqMet] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [focus, setFocus] = useState(false);
  const passwordFocus = () => {
    if (!reqMet) {
      setFocus(true);
    }
  };

  const passwordBlur = () => {
    setFocus(false);
  };

  useEffect(() => {
    if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
      setReqMet(true);
      setFocus(false);
    } else setReqMet(false);
  }, [validLength, hasNumber, upperCase, lowerCase, match, specialChar]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setModal(true);
  };

  const setFirst = (event) => {
    console.log(match);
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  const handleReauthenticate = async (e) => {
    e.preventDefault();
    let uid = user?.id || params.userId;
    console.log(uid, password.firstPassword);
    signOutUser();
    updatePassword({ uid, password: password.firstPassword })
      .then((resp) => {
        setMessage(resp.data.status);
        setSuccess(resp.data.success);
        setAlert(true);
        setTimeout(() => {
          navigate("/community/login");
        }, 8000);
      })
      .catch((error) => {
        setMessage(error.message);
        setSuccess(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 8000);
      });
    setModal(false);
  };

  return (
    <>
        <NameUpdateForm />
          <form
                  className="flex flex-col items-center gap-4 text-center xs:w-5/6"
                  onSubmit={handlePasswordSubmit}
                >
                  <h1 className="mt-4 text-4xl xs:text-xl text-white">{t("Update Password")}</h1>
                  <div className="flex flex-col gap-2 relative xs:w-full"> 
                      <input
                        onChange={setFirst}
                        onFocus={passwordFocus}
                        onBlur={passwordBlur}
                        className={`shadow-sm appearance-none rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight ${reqMet ? "focus:border-8 focus:border-green-500 focus:ring-4 focus:ring-green-400": 
                "focus:border-8 focus:border-red-500 focus:ring-4 focus:ring-red-500"}`}
                        type="password"
                        id="password"
                        name="password"
                        placeholder={t("Password")}
                        required
                      />
                      {focus ? (
                        <div className="w-3/4 text-white text-md bg-community-bg-secondary absolute bottom-0 left-1/2 translate-y-[100%] -translate-x-[50%] border-2 border-white p-4 rounded-lg">
                        <ul className=" font-semibold text-center">
                          <li className="mb-2">1 UpperCase</li>
                          <li className="mb-2">1 LowerCase</li>
                          <li className="mb-2">1 Number</li>
                          <li className="mb-2">1 Symbol</li>
                          <li className="mb-2">8 characters</li>
                        </ul>
                        </div>
                      ) : null}
                    </div>
                      <input
                        onChange={setSecond}
                        className={`shadow-sm appearance-none rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight ${match ? "focus:border-8  focus:border-green-500 focus:ring-4 focus:ring-green-400":
                "focus:border-8 focus:border-red-500 focus:ring-4 focus:ring-red-500"}`}
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder={t("Confirm Password")}
                        required
                      />
                  <div className="flex jus items-center mt-4">
                    {reqMet & match ? (
                      <button
                    type="submit"
                    className="bg-community-button-primary text-white text-xl font-semibold w-96 xs:w-full xs:text-lg py-2 rounded-xl focus:outline-none focus:shadow-outline mt-3"
                  >
                    {t("Update Password")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={true}
                    className="bg-gray-500 text-white text-xl font-semibold w-96 xs:w-full xs:text-lg py-2 rounded-xl focus:outline-none focus:shadow-outline mt-3"
                  >
                    {t("Update Password")}
                  </button>
                    )}
                  </div>
                </form>
                {modal ? (
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none">
                    <div className="relative my-6 mx-auto w-[20vw]">
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-700 bg-cover outline-none ">
                        <div className="p-5 border-b border-solid border-slate-200 rounded-t text-center">
                          <h3 className="text-xl text-white font-bold pt-2">
                            Confirm Password Change
                          </h3>
                          <button
                            className="bg-community-button-primary text-white text-xl font-semibold w-96 xs:w-full xs:text-lg py-2 rounded-xl focus:outline-none focus:shadow-outline mt-3"
                            onClick={() => setModal(false)}
                          >
                            <span className="bg-transparent text-white h-6 w-6 text-2xl block outline-none focus:outline-none absolute top-0.5 right-1">
                              ×
                            </span>
                          </button>
                        </div>
                        <div className="relative p-3 flex flex-row justify-evenly text-white ">
                          <button
                            onClick={handleReauthenticate}
                            className=" py-2 px-4 text-xs font-semibold  text-white uppercase transition duration-150 ease-in-out bg-community-primary border border-transparent rounded-md active:bg-gray-900 false"
                          >
                            {t("Change Password")}
                          </button>
                          <button
                            onClick={() => setModal(false)}
                            className=" py-2 px-4 text-xs font-semibold  text-white uppercase transition duration-150 ease-in-out bg-community-primary border border-transparent rounded-md active:bg-gray-900 false"
                          >
                            {t("Cancel")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
      {/* {alert ? <Popup message={message} success={success} /> : null} */}
    </>
  );
};

export default Profile;