import { createContext, useContext, useEffect, useState } from "react";
import firebaseApp from "./firebaseConfig";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
  updatePassword,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  RecaptchaVerifier
} from "firebase/auth";
import { logOauth2Error } from "./firebase-firestore";

const auth = getAuth(firebaseApp);
setPersistence(auth, browserLocalPersistence);
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState();
  
 

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
 

  const signUp = async (email, password) => {
    return await createUserWithEmailAndPassword(auth, email, password);
  };

  const signOutUser = () => {
    return signOut(auth);
  };

  const userIsMember = () => {
    return (
      user?.getIdTokenResult()?.claims?.milspouse &&
      user?.getIdTokenResult()?.claims?.active
    );
  };

  const addDisplayName = async (createdUser, displayName) => {
    return await updateProfile(createdUser, { displayName });
  };

  const reauthUser = (email, password) => {
    const credentials = EmailAuthProvider.credential(email, password);
    reauthenticateWithCredential(user, credentials);
  };

  const newPassword = (Password) => {
    return updatePassword(user, Password);
  };

  const sendResetEmail = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const resetPassword = (code, password) => {
    return confirmPasswordReset(auth, code, password);
  };

  const verifyCode = (code) => {
    return verifyPasswordResetCode(auth, code);
  };

  const makeRecaptcha = (uiElementId, config) => {
    return new RecaptchaVerifier(
      uiElementId,
      { size: "invisible", ...config },
      auth,
    );
  };

  const makePhoneAuthProvider = () => {
    return new PhoneAuthProvider(auth);
  };

  const handleMultiFactorSignIn = (
    error,
    shouldMfaStart,
    setMfaResolver,
    setFormAlert,
  ) => {
    // User is a multi-factor user. Second factor challenge is required.
    const resolver = getMultiFactorResolver(auth, error);
    // TODO: when multiple mfa phone numbers are present, allow user to select which to use
    // by displaying 'hints' and allowing them to select. Pass the index of the selection here
    if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      setMfaResolver(resolver);
      shouldMfaStart(true);
    } else {
      setFormAlert({
        type: "error",
        message: "Unsupported second factor.",
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (
        user &&
        (window.location.pathname === "/community/verification-failed" ||
          window.location.pathname === "/community/verification")
      ) {
        signOut(auth);
      }

      if (user && user.displayName) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            console.log("ID Token ", idTokenResult);
            if (idTokenResult.claims.milspouse && idTokenResult.claims.active) {
              redirectToOauth(
                user,
                `${process.env.REACT_APP_BASE_URL}/authentication`,
              );
            } else if (idTokenResult.claims.role == "SKR" && idTokenResult.claims.active) {
              window.location.replace(
                `${process.env.REACT_APP_BASE_URL}/community/verification?role=SKR`,
              );
            }
            else if (window.location.pathname === "/community/login") {
              window.location.replace(
                `${process.env.REACT_APP_BASE_URL}//community/login-failed`,
              );
            }
          })
          .catch((error) => {
            console.log(error);
            const errorInstance = {
              email: user.email,
              name: user.displayName,
              action: "Auth State Change",
              description: "Error with get Id Token on Auth State Change",
              error,
            };
            logOauth2Error(errorInstance);
          });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        signUp,
        signIn,
        signOutUser,
        addDisplayName,
        reauthUser,
        newPassword,
        sendResetEmail,
        resetPassword,
        verifyCode,
        userIsMember,
        handleMultiFactorSignIn,
        makeRecaptcha,
        makePhoneAuthProvider
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

const redirectToOauth = async (user, url) => {
  if (!user) {
    return;
  }
  const authToken = new URLSearchParams(window.location.search).get(
    "authToken",
  );

  console.log(authToken);
  if (!authToken) {
    return;
  }

  console.log(user.uid);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth_token: authToken,
      id_token: await user.getIdToken(),
      success: "true",
    }),
  });

  const data = await response.json();

  window.location = data.url;
};

export function useUserAuth() {
  return useContext(userAuthContext);
}
