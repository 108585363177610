import { useUserAuth } from "../firebase-auth";

const Loading = () => {
  const { user } = useUserAuth();
  const Bob = user?.displayName || "";
  const greeting = `Welcome ${Bob},`;

  return (
    <main className="w-full h-full flex">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-[#19415E]"></div>
      </div>
    </main>
  );
};

export default Loading;
