import firebaseApp from "./firebaseConfig";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(firebaseApp);

const updatePassword = httpsCallable(functions, "communityPasswordUpdate");
const updateName = httpsCallable(functions, "communityNameUpdate");
const getUser = httpsCallable(functions, "communityGetUser");
const checkUser = httpsCallable(functions, "communityCheckUser");
const userIP = httpsCallable(functions, "userIP-userIP");
const accessLog = httpsCallable(functions, "updateAccessLog");
const communityNameUpdate = httpsCallable(functions, "communityNameCheck")

export { accessLog, updatePassword, updateName, getUser, checkUser, userIP, communityNameUpdate };
